




import { MetaInfo } from 'vue-meta'
import { Component, Vue } from 'vue-property-decorator'

import DepositoryCoursesTable from '@/components/tables/DepositoryCoursesTable.vue'

@Component({
  components: { DepositoryCoursesTable },
})
export default class CoursesList extends Vue {
  private metaInfo (): MetaInfo {
    return {
      title: 'База знаний - Курсы',
    }
  }
}
