

















































import { isEqual } from 'lodash'
import { Bind, Debounce } from 'lodash-decorators'
import { Component, Mixins } from 'vue-property-decorator'

import Select from '@/components/_uikit/controls/Select.vue'
import TableFooter from '@/components/_uikit/TableFooter.vue'
import TextInput from '@/components/_uikit/controls/TextInput.vue'
import NotifyMixin from '@/mixins/NotifyMixin'
import DictionaryModule from '@/store/modules/dictionary'
import ManagerDepositoryModule from '@/store/modules/manager/depository'
import { CourseShortResource, IManagerCoursesFilter, IManagerCoursesFilterType } from '@/store/types'
import { tableFooterOptions } from '@/utils/constants'

@Component({
  components: {
    Select,
    TableFooter,
    TextInput,
  },
})
export default class DepositoryCoursesTable extends Mixins(NotifyMixin) {
  private footerOptions = tableFooterOptions

  private get filter () {
    return ManagerDepositoryModule.coursesFilter
  }

  private set filter (filter: IManagerCoursesFilter) {
    if (!isEqual(filter, this.filter) || this.courses.length === 0) {
      ManagerDepositoryModule.setCoursesFilter(filter)
      this.fetchCourses()
    }
  }

  private get subjects () {
    return DictionaryModule.subjects
  }

  private get headers () {
    return [
      { sortable: false, text: '#', value: 'id' },
      { text: 'Курс', value: 'title' },
      { text: 'Предмет', value: 'subject' },
      { text: 'Полезные материалы', value: 'usefulMaterials' },
      { text: 'Учебные файлы', value: 'trainingFiles' },
    ]
  }

  private get courses () {
    return ManagerDepositoryModule.courses.data
  }

  private get pagination () {
    return ManagerDepositoryModule.courses.meta
  }

  @Bind
  @Debounce(300)
  private fetchCourses () {
    ManagerDepositoryModule.fetchCourses()
      .catch(this.notifyError)
  }

  private handleFilter (field: IManagerCoursesFilterType, value: never) {
    this.filter = {
      ...this.filter,
      [field]: value,
      page: 1,
    }
  }

  private handleRowClick (course: CourseShortResource) {
    this.$router.push({ name: 'manager.bank.depository.courses.item', params: { courseID: course.id.toString() } })
  }
}
